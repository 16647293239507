import React from "react";

import Gallery from "./Gallery";

import "./Comix.scss";

export default function Comix() {
  return (
    <div className="comix">
      <div className="comix__header">comix!</div>
      <p>(Click on the image to zoom in/out)</p>
      <Gallery
        src={[
          "fat_alien.jpg",
          "unfamiliarshit-images-0.jpg",
          "unfamiliarshit-images-1.jpg",
          "unfamiliarshit-images-2.jpg",
          "unfamiliarshit-images-3.jpg",
          "unfamiliarshit-images-4.jpg",
          "unfamiliarshit-images-5.jpg",
          "unfamiliarshit-images-6.jpg",
          "unfamiliarshit-images-7.jpg",
          "unfamiliarshit-images-8.jpg",
          "unfamiliarshit-images-9.jpg",
          "quar0.jpg",
          "quar1.jpeg",
          "quar2.jpeg",
          "quar3.jpeg",
          "quar4.jpeg",
          "quar5.png",
          "quar6.png",
          "quar7.png",
          "quar8.png",
          "quar9.png",
        ]}
      />
    </div>
  );
}
