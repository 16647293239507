import React from "react";

import "./Gallery.scss";

function Gallery(props) {
  const [state, setState] = React.useState({ index: 0 });
  const [expanded, setExpanded] = React.useState(false);

  const refScrollUp = React.useRef();

  const modalScrollUp = React.useRef();

  const scrollUp = () => {
    if (expanded) {
      modalScrollUp.current.scrollIntoView({ behavior: "smooth" });
    } else {
      refScrollUp.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  const handlePreviousImage = () => {
    scrollUp();
    const { index } = state;
    if (index > 0) setState({ index: index - 1 });
  };

  const handleNextImage = () => {
    scrollUp();
    const { index } = state;
    const lastIndex = props.src?.length - 1;
    if (index < lastIndex) setState({ index: index + 1 });
  };

  const expandImage = () => {
    setExpanded(true);
  };

  const collapseImage = () => {
    setExpanded(false);
  };

  const { src } = props;
  const { index } = state;

  const lastIndex = src?.length - 1;
  const isPrevDisabled = index === 0;
  const isNextDisabled = index >= lastIndex;

  return (
    <div
      ref={refScrollUp}
      className={`gallery ${expanded ? "gallery__modal" : ""}`}
    >
      {expanded ? (
        <div ref={modalScrollUp} className="gallery__modalContent">
          <img src={src[index]} alt="" onClick={collapseImage} loading="lazy" />
          <div className="gallery__nav">
            <button
              className="gallery__button"
              disabled={isPrevDisabled}
              onClick={handlePreviousImage}
            >
              ← Prev
            </button>
            <button
              className="gallery__button"
              disabled={isNextDisabled}
              onClick={handleNextImage}
            >
              Next →
            </button>
          </div>
        </div>
      ) : (
        <>
          <button
            className="gallery__button"
            disabled={isPrevDisabled}
            onClick={handlePreviousImage}
          >
            ← Prev
          </button>
          <img src={src[index]} alt="" onClick={expandImage} loading="lazy" />
          <button
            className="gallery__button"
            disabled={isNextDisabled}
            onClick={handleNextImage}
          >
            Next →
          </button>
        </>
      )}
    </div>
  );
}

export default Gallery;
